// ... (your existing imports)
import React, { useState, useEffect } from "react";
import { GridComponent, ColumnsDirective, ColumnDirective, Page, Toolbar, Edit, Inject } from "@syncfusion/ej2-react-grids";
import { toast } from "react-hot-toast";
import { IoCloudDownload } from "react-icons/io5";
import { Link } from "react-router-dom";
import { getUserNameData } from "../api/Api";
import { useContextSelector } from "use-context-selector";
import { StateContext } from "../contexts/StateContext";

function UserDashboard() {
  const [loading, setLoading] = useState(false);
  const [data, setData] = useState([]);
  const userName = useContextSelector(
    StateContext,
    (state) => state.userName
  );

  const loadData = () => {
    setLoading(true);
    getUserNameData(userName)
      .then((res) => {
        toast.success(res.data.message);
        setData(res.data.data);
        setLoading(false);
      })
      .catch((error) => {
        // Handle errors
        setLoading(false);
        console.log(error);
      });
  };

  useEffect(() => {
    loadData();
  }, []);

  const actionTemplate = (props) => {
    return (
      <Link
        to={{
          pathname: "/viewOrderList",
        }}
        state={{
          name: props.name,
          email: props.email,
         // orderList: props.orderList,
        }}
        exact
      >
        <button
          className="px-2 text-sm border-1 border-brightColor bg-backgroundColor              
                  hover:text-brightColor transition-all rounded-lg font-bold uppercase "
          type="button"
        >
          {" "}
          <span>
            <IoCloudDownload />
          </span>{" "}
        </button>
      </Link>
    );
  };

  return (
    <div className="mt-28 mb-16">
    <div className="control-pane flex-1 m-5">
    <h2 className="font-semibold my-4 text-2xl text-center">
                    Dashboard
                  </h2>
      <div className="control-section">
        <GridComponent
          dataSource={data}
          allowPaging={true}
          pageSettings={{ pageCount: 5 }}
          selectionSettings={{ persistSelection: true }}
          toolbar = {["Search"]}
          //toolbar={["Add", "Edit", "Delete", "Search"]}
          //editSettings={{
           // allowEditing: true,
           // allowAdding: true,
            //allowDeleting: true,
           // newRowPosition: "Top",
         // }}
        >
          <ColumnsDirective>
            <ColumnDirective
              field="name"
              headerText="Name"
              width="150"
              textAlign="Center"
              isPrimaryKey={true}
            />
            <ColumnDirective
              field="email"
              headerText="Email"
              width="150"
              textAlign="Center"
            />
            <ColumnDirective
              field="mobile"
              headerText="Mobile No"
              width="150"
              textAlign="Center"
            />
            <ColumnDirective
              field="planName"
              headerText="Plan Name"
              width="150"
              textAlign="Center"
              //template={actionTemplate}
            />
             <ColumnDirective
              field="planCost"
              headerText="Plan Cost"
              width="150"
              textAlign="Center"
              //template={actionTemplate}
            />
             <ColumnDirective
              field="planDuration"
              headerText="PlanDuration"
              width="150"
              textAlign="Center"
              //
            />
            <ColumnDirective
              field="paymentStatus"
              headerText="Payment Status"
              width="150"
              textAlign="Center"
              template={actionTemplate}
            />
          </ColumnsDirective>
          <Inject services={[Page, Toolbar, Edit]} />
        </GridComponent>
      </div>
    </div>
    </div>
  );
}

export default UserDashboard;
