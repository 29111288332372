import React, { useState } from "react";
import { HashLink } from "react-router-hash-link";
import { ACCESS_TOKEN, ADMIN_TOKEN } from "../../config/Config";
import toast, { Toaster } from "react-hot-toast";
import { useNavigate } from "react-router-dom";

const SidebarLinks = ({ menuClick }) => {
    const navigate = useNavigate();
    const [showTNPSCDropdown, setShowTNPSCDropdown] = useState(false);
    const [showTrainingDropdown, setShowTrainingDropdown] = useState(false);

    const handleTNPSCDropdownToggle = () => {
        setShowTNPSCDropdown(!showTNPSCDropdown);
        setShowTrainingDropdown(false); // Close training dropdown if open
    };

    const handleTrainingDropdownToggle = () => {
        setShowTrainingDropdown(!showTrainingDropdown);
        setShowTNPSCDropdown(false); // Close TNPSC dropdown if open
    };

    const logout = () => {
        localStorage.clear();
        localStorage.removeItem(ACCESS_TOKEN);
        if (typeof menuClick === "function") {
            menuClick();
        } else {
            console.warn("menuClick is not a function");
        }
        toast.success("Logout successfully");
        navigate("/");
    };

    return (
        <div className="w-64 h-full bg-white text-gray-500 flex flex-col">
            <HashLink
                className="px-4 py-2 font-extrabold hover:bg-blue-900"
                smooth
                to="/"
                onClick={menuClick}
            >
                Home
            </HashLink>
            <HashLink
                className="px-4 py-2 font-extrabold hover:bg-blue-900"
                smooth
                to="/about"
                onClick={menuClick}
            >
                About
            </HashLink>
            <div className="relative">
                <button
                    className="px-4 py-2 w-full text-left font-extrabold hover:bg-blue-900 flex justify-between items-center focus:outline-none"
                    onClick={handleTNPSCDropdownToggle}
                >
                    TNPSC
                    <svg
                        className={`w-4 h-4 transition-transform ${showTNPSCDropdown ? 'transform rotate-180' : ''}`}
                        fill="none"
                        stroke="currentColor"
                        viewBox="0 0 24 24"
                        xmlns="http://www.w3.org/2000/svg"
                    >
                        <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M19 9l-7 7-7-7" />
                    </svg>
                </button>
                {showTNPSCDropdown && (
                    <div className="font-extrabold">
                        <HashLink
                            className="block px-4 py-2 hover:bg-blue-900"
                            smooth
                            to="/#services"
                            onClick={menuClick}
                        >
                            Group IV & VAO
                        </HashLink>
                        <HashLink
                            className="block px-4 py-2 hover:bg-blue-900"
                            smooth
                            to="/assistantEngineer"
                            onClick={menuClick}
                        >
                            Combined Engineering (CESE)
                        </HashLink>
                        <HashLink
                            className="block px-4 py-2 hover:bg-blue-900"
                            smooth
                            to="/subInspector"
                            onClick={menuClick}
                        >
                            Sub Inspector
                        </HashLink>
                        <HashLink
                            className="block px-4 py-2 hover:bg-blue-900"
                            smooth
                            to="/policeConstable"
                            onClick={menuClick}
                        >
                            Police Constable
                        </HashLink>
                    </div>
                )}
            </div>
            <div className="relative">
                <button
                    className="px-4 py-2 w-full text-left font-extrabold hover:bg-blue-900 flex justify-between items-center focus:outline-none"
                    onClick={handleTrainingDropdownToggle}
                >
                    Training
                    <svg
                        className={`w-4 h-4 transition-transform ${showTrainingDropdown ? 'transform rotate-180' : ''}`}
                        fill="none"
                        stroke="currentColor"
                        viewBox="0 0 24 24"
                        xmlns="http://www.w3.org/2000/svg"
                    >
                        <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M19 9l-7 7-7-7" />
                    </svg>
                </button>
                {showTrainingDropdown && (
                    <div className="font-extrabold">
                        <HashLink
                            className="block px-4 py-2 hover:bg-blue-900"
                            smooth
                            to="/computerCourse"
                            onClick={menuClick}
                        >
                            Summer computer course
                        </HashLink>
                        <HashLink
                            className="block px-4 py-2 hover:bg-blue-900"
                            smooth
                            to="/communication"
                            onClick={menuClick}
                        >
                            Communication training
                        </HashLink>
                        <HashLink
                            className="block px-4 py-2 hover:bg-blue-900"
                            smooth
                            to="/counselling"
                            onClick={menuClick}
                        >
                            Engineering counselling
                        </HashLink>
                        <HashLink
                            className="block px-4 py-2 hover:bg-blue-900"
                            smooth
                            to="/placement"
                            onClick={menuClick}
                        >
                            Placement training
                        </HashLink>
                        <HashLink
                            className="block px-4 py-2 hover:bg-blue-900"
                            smooth
                            to="/project"
                            onClick={menuClick}
                        >
                            Project training
                        </HashLink>
                    </div>
                )}
            </div>
            <HashLink
                className="px-4 py-2 font-extrabold hover:bg-blue-900"
                smooth
                to="/contact#contact"
                onClick={menuClick}
            >
                Contact Us
            </HashLink>
            {localStorage.getItem(ADMIN_TOKEN) ? (
                <>
                    <HashLink
                        className="text-white bg-blue-900 hover:bg-blue-800 inline-flex items-center justify-center w-auto px-6 py-3 shadow-xl rounded-xl"
                        smooth
                        to="/adminDashboard"
                    >
                        Dashboard
                    </HashLink>
                    <button
                        className="text-white bg-blue-900 hover:bg-blue-800 inline-flex items-center justify-center w-auto px-6 py-3 shadow-xl rounded-xl"
                        onClick={logout}
                    >
                        Logout
                    </button>
                </>
            ) : localStorage.getItem(ACCESS_TOKEN) ? (
                <button
                    className="text-white bg-blue-900 hover:bg-blue-800 inline-flex items-center justify-center w-auto px-6 py-3 shadow-xl rounded-xl"
                    onClick={logout}
                >
                    Logout
                </button>
            ) : (
                <HashLink
                    className="text-white bg-blue-900 hover:bg-blue-800 inline-flex items-center justify-center w-auto px-6 py-3 shadow-xl rounded-xl"
                    smooth
                    to="/login"
                    onClick={menuClick}
                >
                    Login
                </HashLink>
            )}
            <Toaster
                position="bottom-right"
                reverseOrder={false}
                toastOptions={{
                    error: {
                        duration: 6000,
                        style: {
                            background: "#FF4136",
                            color: "#fff",
                        },
                    },
                    success: {
                        duration: 6000,
                        style: {
                            background: "#008000",
                            color: "#fff",
                        },
                    },
                }}
            />
        </div>
    );
};

export default SidebarLinks;
